import PageWrapper from "components/PageWrapper";
import AdminUsersTable from "./admin-users-table";

function AdminOnboarding() {
  return (
    <PageWrapper>
      < AdminUsersTable />
    </PageWrapper>
  );
}

export default AdminOnboarding;
