import React, { ReactNode, useState } from "react";
import {
  Button,
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  useCreateInvestorMutation,
  useUpdateInvestorMutation,
  useLazyGetInvestorQuery,
} from "./api/entity-mapping.generated";
import Header from "components/Header";
import SearchIcon from "@mui/icons-material/Search";
import PageWrapper from "components/PageWrapper";
import { hasPermission, Permission } from "core/utils/roles";
import { useAuth } from "app/authHooks";
import { Formik } from "formik";
import * as yup from "yup";

type MappingField = {
  label: string;
  value: string;
  name: string;
};

type FormValues = {
  customer_id: string;
  external_id: string;
  internal_id: string;
};

const defaultCreateMappingValue = {
  customerId: { label: "Customer Id", value: "", name: "customer_id" },
  externalId: { label: "External Id", value: "", name: "external_id" },
  internalId: { label: "Internal Id", value: "", name: "internal_id" }
};

const entityMappingSchema = yup.object({
  customer_id: yup.string().required("Customer Id is required."),
  internal_id: yup.string().required("Internal Id is required."),
  external_id: yup.string().required("External Id is required."),
});

type MappingKeys = keyof typeof defaultCreateMappingValue;

type CreateMappingValue = {
  [K in MappingKeys]: MappingField;
};

const EntityMappingScreen = () => {
  const TablePlaceholder: React.FC<{ children: ReactNode }> = ({ children }) => (
    <TableRow>
      <TableCell colSpan={9} align="center">
        {children}
      </TableCell>
    </TableRow>
  );
  const [editTableRow, setEditTableRow] = useState<any>(null); // Store original row data when editing

  const extractFieldValues = () => {
    return Object.values(defaultCreateMappingValue).reduce((acc, { name, value }) => {
      return { ...acc, [name]: value };
    }, {} as FormValues);
  };

  const { attributes } = useAuth();
  const theme = useTheme();
  const colors = theme.palette;
  const [searchId, setSearchId] = useState<string>(""); // Input for search
  const [mappings, setMappings] = useState<any[]>([]); // Table data
  const [editRow, setEditRow] = useState<string | null>(null); // Row being edited
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false); // Create dialog state
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);


  // Lazy Query and Mutations
  const [getInvestorQuery] = useLazyGetInvestorQuery(); // Lazy query
  const [createInvestorMutation] = useCreateInvestorMutation();
  const [updateInvestorMutation] = useUpdateInvestorMutation();

  const formatError = (error: any): string | undefined => {
    if (typeof error === "string") {
      return error;
    } else if (Array.isArray(error)) {
      return error.join(", ");
    } else if (typeof error === "object") {
      return "An error occurred";
    }
    return undefined;
  };

  const handleSearch = async () => {
    if (!searchId.trim()) {
      enqueueSnackbar("Please enter a valid HW Investor ID to search.", { variant: "warning" });
      return;
    }
    try {
      setLoading(true);
      const response = await getInvestorQuery({ internalId: searchId });
      if (response.data?.getInvestor?.success) {
        const fetchedData = response.data.getInvestor.data || [];
        setMappings(fetchedData);
      } else {
        enqueueSnackbar(response.data?.getInvestor?.errorMessage || "Error fetching data.", { variant: "error" });
        setMappings([]);
      }
    } catch (error) {
      console.error("Error fetching investor mappings:", error);
      enqueueSnackbar("Error fetching investor mappings.", { variant: "error" });
    }
    finally {
      setLoading(false);
    }
  };


  const handleEdit = (id: string) => {
    const rowToEdit = mappings.find(row => row.id === id);
    if (rowToEdit) {
      setEditTableRow(rowToEdit); // Store original data
      setEditRow(id);
    }
  };

  const handleCancelEdit = () => {
    if (editTableRow) {
      setMappings(prev => prev.map(row =>
        row.id === editRow ? editTableRow : row
      ));
    }
    setEditRow(null);
    setEditTableRow(null);
  };

  const handleSave = async (row: any) => {
    try {
      const updatedData = {
        id: row.id,
        externalId: row.externalId,
        internalId: row.internalId, // Check if this has changed
        customerId: row.customerId,
      };

      // Optimistically update the UI
      setMappings((prev) =>
        prev.map((r) => (r.id === row.id ? { ...r, ...updatedData } : r))
      );

      // API Call
      const response = await updateInvestorMutation({ input: updatedData });

      if (response.data?.updateInvestor?.success) {
        enqueueSnackbar("Record updated successfully.", { variant: "success" });

        // Determine which internalId to use for re-fetching data
        const newInternalId = row.internalId || searchId; // Use updated internalId if changed

        // Re-fetch data to get latest values
        await getInvestorQuery({ internalId: newInternalId });
      } else {
        enqueueSnackbar(response.data?.updateInvestor?.errorMessage || "Update failed.", { variant: "error" });
      }
    } catch (error) {
      console.error("Error updating record:", error);
      enqueueSnackbar("Failed to update record.", { variant: "error" });
    }
    finally {
      setEditRow(null);
    }
  };

  // Open "Create" Dialog
  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true);
  };

  // Close "Create" Dialog
  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
    setLoading(false);
  };

  // Handle Creating a New Record
  const handleCreateSave = async (values: typeof entityMappingSchema.__outputType) => {
    try {
      const investorMutationObj = {
        customerId: values.customer_id,
        internalId: values.internal_id,
        externalId: values.external_id,
      };
      setLoading(true);
      const response = await createInvestorMutation({
        input: investorMutationObj
      });
      if (response.data?.createInvestor?.success) {
        handleCloseCreateDialog();
        enqueueSnackbar("New investor mapping created successfully.", { variant: "success" });
      } else {
        const errorMessage = response.data?.createInvestor?.errorMessage;
        if (errorMessage === "User cannot be created because the customerId does not have a valid tenant mapping.") {
          enqueueSnackbar(
            "Creation failed: Mapping for the provided customerId is not available in IO Integrations settings.",
            { variant: "warning" }
          );
        } else {
          enqueueSnackbar(errorMessage || "Creation failed.", { variant: "error" });
        }
      }
    } catch (error) {
      console.error("Error creating new investor mapping:", error);
      enqueueSnackbar("Failed to create new investor mapping.", { variant: "error" });
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper>
      <Box sx={{ paddingBottom: "64px" }}>
        <Header
          title="Entity Mapping"
          subtitle="Manage Entity Mappings"
        />
        <Typography variant="h2"></Typography>

        <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: 30 }}>
          <Box style={{ display: "flex", gap: 20 }}>
            <TextField
              label="Search by HW Investor ID"
              variant="outlined"
              size="small"
              value={searchId}
              onChange={(e) => setSearchId(e.target.value)}
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSearch}
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
          </Box>
          {hasPermission(attributes, "create:entity-mapping" as Permission) && (
            <Box>
              <Button variant="contained" color="primary" onClick={handleOpenCreateDialog}>
                Create Investor Mapping
              </Button>
            </Box>
          )}
        </Box>

        {/* Results Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Customer ID</TableCell>
                <TableCell>External ID</TableCell>
                <TableCell>Internal ID</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>Tenant ID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TablePlaceholder>
                  <CircularProgress />
                </TablePlaceholder>
              )}
              {!loading && !mappings?.length && (
                <TablePlaceholder>
                  <Typography variant="h5">No Data</Typography>
                </TablePlaceholder>
              )}
              {!loading && mappings.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.customerId}</TableCell>
                  <TableCell>
                    {editRow === row.id ? (
                      <TextField
                        value={row.externalId}
                        onChange={(e) =>
                          setMappings((prev) =>
                            prev.map((r) =>
                              r.id === row.id ? { ...r, externalId: e.target.value } : r
                            )
                          )
                        }
                        size="small"
                        style={{ width: "120px" }}
                      />
                    ) : (
                      row.externalId
                    )}
                  </TableCell>
                  <TableCell>
                    {editRow === row.id ? (
                      <TextField
                        value={row.internalId}
                        onChange={(e) =>
                          setMappings((prev) =>
                            prev.map((r) =>
                              r.id === row.id ? { ...r, internalId: e.target.value } : r
                            )
                          )
                        }
                        size="small"
                        style={{ width: "120px" }}
                      />
                    ) : (
                      row.internalId
                    )}
                  </TableCell>
                  <TableCell>{row.provider}</TableCell>
                  <TableCell>{row.tenantId}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>
                    {hasPermission(attributes, "update:entity-mapping" as Permission) && (
                      <>
                        {editRow === row.id ? (
                          <div style={{ display: "flex", gap: "8px" }}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleSave(row)}
                            >
                              Save
                            </Button>
                            <Button variant="outlined" onClick={handleCancelEdit}>
                              Cancel
                            </Button>
                          </div>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleEdit(row.id)}
                          >
                            Edit
                          </Button>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Create Investor Mapping Dialog */}
        <Dialog open={createDialogOpen} onClose={handleCloseCreateDialog}>
          <DialogTitle><Typography variant="h4">Create Investor Mapping</Typography></DialogTitle>
          <Formik
            onSubmit={handleCreateSave}
            initialValues={extractFieldValues()}
            validationSchema={entityMappingSchema}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              handleSubmit,
              touched,
              handleBlur,
              handleChange,
              resetForm,
            }) => {
              const handleClose = () => {
                handleCloseCreateDialog();
                resetForm();
              };

              return (
                <form onSubmit={handleSubmit}>
                  <DialogContent>
                    {(Object.keys(defaultCreateMappingValue) as (keyof CreateMappingValue)[]).map((key) => {
                      const field = defaultCreateMappingValue[key];
                      const fieldName = field.name as keyof typeof values;

                      return (
                        <TextField
                          key={key}
                          label={field.label}
                          name={fieldName}
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          margin="normal"
                          value={values[fieldName]}
                          error={!!touched[fieldName] && !!errors[fieldName]}
                          helperText={
                            touched[fieldName]
                              ? formatError(errors[fieldName])
                              : undefined
                          }
                          onChange={handleChange}
                        />
                      );
                    })}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined" >
                      Cancel
                    </Button>
                    <Button type="submit" disabled={loading} color="primary" variant="contained" endIcon={loading ? <CircularProgress size={20} /> : null}>
                      {loading ? "Saving" : "Save"}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </Dialog>
      </Box>
    </PageWrapper >
  );
};

export default EntityMappingScreen;
