import { useNavigate, useParams } from "react-router";
import { Box, FormControl, Grid, TextField, Link, Button, Typography, CircularProgress, styled, InputLabel, Select, MenuItem } from "@mui/material";
import { CardComponent } from "components/card/Card";
import * as yup from "yup";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { enqueueSnackbar } from "notistack";
import loadingStore from "core/utils/loading";
import { observer } from "mobx-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { ErrorMessage as FormikErrorMessage, Formik } from "formik";
import PageWrapper from "components/PageWrapper";
import { useAuth } from "app/authHooks";
import { hasPermission, Permission } from "core/utils/roles";
import adminStore from "../api";
import { Switch, FormControlLabel } from "@mui/material"; // Import Switch Component


function AddEditAdminUsers() {
  const { attributes } = useAuth();
  const navigate = useNavigate();
  const { getAdmin, createAdmin, updateAdmin } = adminStore;
  const { loading } = loadingStore;
  const [signInWithEmail, setSignInWithEmail] = useState(false);


  const canUpdateadmin = hasPermission(attributes, "update:admin-onboarding" as Permission);

  const initialValues = {
    customer_id: "",
    employee_id: "",
    name: "",
    username: "",
    email: "",
    role: "",
    username_provided: true
  } as any;

  // const adminUserScheme = yup.object({
  //     customer_id: yup.string().min(7).max(10).optional(),
  //     employee_id: yup.string().min(7).max(11).optional(),
  //     name: yup.string().max(50).optional(),
  //     username: yup.string().when("username_provided", (usernameProvided, schema) => {
  //         return usernameProvided
  //             ? schema.min(5).max(50).required("Username is required.")
  //             : schema.notRequired();
  //     }),
  //     email: yup.string().email().required("Email is required."),
  //     role: yup.string().max(10).required()
  // });
  const adminUserSchema = yup.object({
    customer_id: yup.string().min(7).max(10).optional(),
    employee_id: yup.string().min(7).max(11).optional(),
    name: yup.string().max(50).optional(),
    username: yup.string().when("username_provided", {
      is: true,
      then: (schema) => schema.min(5).max(50).required("Username is required when username_provided is true"),
      otherwise: (schema) => schema.optional()
    }),
    email: yup.string().email().required("Email is required."),
    role: yup.string().max(10).required(),
    username_provided: yup.boolean().required("username_provided field is required")
  });
  const params = useParams();
  const isEdit = !!params?.id;
  const [dataValue, setDataValue] = useState<any>(initialValues);

  useEffect(() => {
    onInit();
  }, []);

  const onInit = async () => {
    if (isEdit) {
      const adminUser = await getAdmin(params?.id);
      console.log("hi", adminUser);
      const emailSignIn = !adminUser.username; // If no username, assume sign-in via email
      setSignInWithEmail(emailSignIn);
      //   investorUser.phone_number = investorUser.phone_number?.replace(/\+/g, "");
      setDataValue({ ...adminUser, username_provided: !emailSignIn });
    } else {
      setDataValue(initialValues);
    }
  };
    type FormValues = {
        customer_id: string;
        employee_id: string;
        name: string;
        username?: string;
        email: string;
        role: string;
        username_provided: boolean;
    };
    const handleSubmitForm = async (values: any) => {
      if (isEdit && params?.id) {
        const response = await updateAdmin(params.id, {
          customer_id: values.customer_id,
          employee_id: values.employee_id,
          role: values.role,
          username: values.username, // Include username
        });
        console.log("Update Response:", response); // Debugging the response

        if (response === true) {
          enqueueSnackbar("Admin user has been successfully updated!!!", { variant: "success" });
          navigate("/admin-users");
        }
        else {
          enqueueSnackbar("Failed to update admin user. Please try again.", { variant: "error" });
        }
      }
      else {
        const response = await createAdmin({
          customer_id: values.customer_id,
          employee_id: values.employee_id,
          name: values.name,
          // username: values.username,
          email: values.email,
          role: values.role,
          username_provided: values.username_provided,
          ...(values.username_provided && { username: values.username })
        });
        if (response && response.id) {
          enqueueSnackbar("admin user has been successfully created!!!", { variant: "success" });
          navigate("/admin-users");
        }
        else {
          enqueueSnackbar("Failed to create admin user. Please try again.", { variant: "error" });
        }

      }
    };

    const formatError = (error: any): string | undefined => {
      if (typeof error === "string") {
        return error;
      } else if (Array.isArray(error)) {
        return error.join(", ");
      } else if (typeof error === "object") {
        return "An error occurred";
      }
      return undefined;
    };

    const ErrorMessage = styled(FormikErrorMessage)`
  margin-left: 1rem;
  color: #d32f2f;
  font-size: 0.7rem;`;

    const StyledPhoneInput = styled(PhoneInput)`
  .flag-dropdown {
    pointer-events: none;
  }`;

    return (
      <PageWrapper>
        <Box sx={{ py: 4, px: 4 }}>
          <Box sx={{ cursor: "pointer", fontSize: "14px", lineHeight: "20px", marginBottom: "16px" }}>
            <Link underline="hover" onClick={() => navigate("/admin-users")}>
              <ArrowBackIcon sx={{ width: "16px", height: "16px", marginRight: "4px" }} /> Back to Admin Users
            </Link>
          </Box>
          <Formik
            onSubmit={handleSubmitForm}
            initialValues={dataValue}
            validationSchema={adminUserSchema}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue

            }) => {
              return <form onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!values.username_provided} // Inverse for clarity
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setSignInWithEmail(isChecked);
                          setFieldValue("username_provided", !isChecked); // Boolean value

                          if (isChecked) {
                            setFieldValue("username", ""); // Clear username if toggled ON
                          }
                        }}
                      />
                    }
                    label="Sign in with Email"
                    sx={{ mb: 2 }}
                  />
                  <CardComponent
                    title={isEdit
                      ? canUpdateadmin
                        ? "Edit Admin User"
                        : "View Admin User"
                      : "Add Admin User"}
                    cardContent={
                      <Box sx={{ "& > *:not(:last-child)": { mb: 8 } }}>
                        <Grid container spacing={2}>
                          <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                            <FormControl style={{ width: "100%" }}>
                              <TextField
                                fullWidth
                                type="text"
                                label="Customer Id"
                                name="customer_id"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.customer_id}
                                error={!!touched.customer_id && !!errors?.customer_id}
                                helperText={touched?.customer_id ? formatError(errors?.customer_id) : undefined}
                                sx={{
                                  gridColumn: "span 4",
                                }}
                                InputLabelProps={{ shrink: true }}
                                disabled={!canUpdateadmin}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                            <FormControl style={{ width: "100%" }}>
                              <TextField
                                fullWidth
                                type="text"
                                label="Employee Id"
                                name="employee_id"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.employee_id}
                                error={!!touched.employee_id && !!errors?.employee_id}
                                helperText={touched?.employee_id ? formatError(errors?.employee_id) : undefined}
                                sx={{
                                  gridColumn: "span 4",
                                }}
                                InputLabelProps={{ shrink: true }}
                                disabled={!canUpdateadmin}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                            <FormControl style={{ width: "100%" }}>
                              <TextField
                                fullWidth
                                type="text"
                                label="Name"
                                name="name"
                                disabled={isEdit}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                error={!!touched.first_name && !!errors?.first_name}
                                helperText={touched?.first_name ? formatError(errors?.first_name) : undefined}
                                sx={{
                                  gridColumn: "span 4",
                                }}
                                InputLabelProps={{ shrink: true }}
                                // disabled={!canUpdateadmin}
                              />
                            </FormControl>
                          </Grid>
                          {/* <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                                                    <FormControl style={{ width: "100%" }}>
                                                        <TextField
                                                            fullWidth
                                                            type="text"
                                                            label="Username"
                                                            name="username"
                                                            disabled={isEdit}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.username}
                                                            error={!!touched.username && !!errors?.username}
                                                            helperText={touched?.username ? formatError(errors?.username) : undefined}
                                                            sx={{
                                                                gridColumn: "span 4",
                                                            }}
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    </FormControl>
                                                </Grid> */}
                          {values.username_provided && (
                            <Grid item md={6} xs={12}>
                              <TextField
                                fullWidth
                                label="Username"
                                name="username"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.username}
                                error={!!touched.username && !!errors.username}
                                helperText={touched.username && typeof errors.username === "string" ? errors.username : ""}
                              />
                            </Grid>
                          )}

                          <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                            <FormControl style={{ width: "100%" }}>
                              <TextField
                                fullWidth
                                type="text"
                                label="Email"
                                name="email"
                                disabled={isEdit}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                error={!!touched.email && !!errors?.email}
                                helperText={touched?.email ? formatError(errors?.email) : undefined}
                                sx={{
                                  gridColumn: "span 4",
                                }}
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                            <FormControl style={{ width: "100%" }}>
                              <InputLabel id="status">Role</InputLabel>
                              <Select
                                fullWidth
                                labelId="role"
                                label="role"
                                placeholder="Role"
                                name="role"
                                value={values.role}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!touched.status && !!errors?.status}>
                                <MenuItem value="SuperAdmin">SuperAdmin</MenuItem>
                                <MenuItem value="AdminEdit">AdminEdit</MenuItem>
                                <MenuItem value="AdminView">AdminView</MenuItem>
                                <MenuItem value="DocEdit">DocEdit</MenuItem>
                                <MenuItem value="DocView">DocView</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                    }
                  />
                  {canUpdateadmin && (
                    <Box width="100%" display="flex" mt="10px" mb="10px" mr="10px">
                      <Button
                        style={{ marginTop: 10, width: "100px" }}
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={loading}
                        endIcon={loading ? <CircularProgress size={20} /> : null}
                        // onClick={console.log("button")}
                      >
                        {isEdit ? (loading ? "UPDATING" : "UPDATE") : (loading ? "SAVING" : "SAVE")}
                      </Button>
                    </Box>
                  )}
                </Box>
              </form>;
            }}
          </Formik>
        </Box>
      </PageWrapper>
    );
}

export default observer(AddEditAdminUsers);
