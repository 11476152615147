import restUtils from "core/utils/rest";
import { Admin , Adminpage } from "../model";


const UMAPI = "UserManagementApi";
const ADMIN = "adminUser";

class AdminStore {

  getAdmins = async (payload: any) => {
    return await restUtils.call<Adminpage>(UMAPI, ADMIN , "GET" , payload);
  };

  getAdmin = async (id: any) => {
    return await restUtils.call<Admin>(UMAPI, ADMIN + `/${id}`, "GET");
  };

  createAdmin = async (payload: any) => {
    return await restUtils.call<any>(UMAPI, ADMIN, "POST", payload);
  };

  updateAdmin = async (id: any, payload: any) => {
    return await restUtils.call<any>(UMAPI, ADMIN + `?id=${id}`, "PUT", payload);
  };
  statusAdmin = async (payload : any) => {
    return await restUtils.call<any>(UMAPI,ADMIN + "/status-change", "POST" , payload );
  };
}

const adminStore = new AdminStore();
export default adminStore;
