import React, { forwardRef, useState } from "react";
import {
  TableCell,
  TableRow,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router";
import MenuComponent, { MenuItem } from "components/MenuComponent";
import { Admin } from "../../model";
import adminStore from "../../api";
import { enqueueSnackbar } from "notistack";
import { hasPermission } from "core/utils/roles";

interface TableRowProps {
  row: Admin;
  attributes: { [key: string]: any }
}

const AdminUsersRow = forwardRef<HTMLTableRowElement, TableRowProps>((props) => {
  const navigate = useNavigate();
  const row = props.row;
  const attributes = props.attributes;

  const options: MenuItem[] = [
    {
      label: hasPermission(attributes, "update:admin-onboarding") ? "Edit" : "View",
      onClick: () => navigate("/admin-users/add-edit/" + row.id),
    },
  ];

  return (
    <>
      <TableRow sx={{ borderBottom: "none", "& > *": { borderBottom: "unset" } }}>
        <TableCell>{row?.name}</TableCell>
        <TableCell>{row?.username}</TableCell>
        <TableCell>{row?.email}</TableCell>
        <TableCell>{row?.role}</TableCell>
        <TableCell>
          <MenuComponent options={options} />
        </TableCell>
      </TableRow>
    </>
  );
});

export default AdminUsersRow;
