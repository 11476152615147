/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../app/services/types.generated';

import { api } from 'app/graphqlBaseApi';
module.hot?.accept();
export type GetAllIntellifloTenantQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.IntellifloTenantFilterInput>;
  LastEvaluatedKey?: Types.InputMaybe<Types.LastEvaluatedInput>;
}>;


export type GetAllIntellifloTenantQuery = { __typename?: 'Query', getAllIntellifloTenant?: { __typename?: 'GetAllIntellifloTenantResponse', data?: { __typename?: 'IntellifloTenantResponseType', scannedCount?: number, LastEvaluatedKey?: { __typename?: 'LastEvaluatedType', type?: string, id?: string, customerId?: string }, intellifloTenants?: Array<{ __typename?: 'IntellifloTenantType', externalId: string, id?: string, internalId: string, metadata?: { __typename?: 'MetadataType', displayName?: string } }> } } };

export type GetTenantIntegrationSettingsQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetTenantIntegrationSettingsQuery = { __typename?: 'Query', getTenantIntegrationsetting: { __typename?: 'GetAllIntellifloIntegrationType', items: Array<{ __typename?: 'IntellifloIntegrationType', id: string, tenantId: string, settings: { __typename?: 'IntegrationSettingsType', allowClientImport?: boolean, allowClientRelationshipImport?: boolean, allowDocumentUpload?: boolean, allowWealthLinkBulkValuation?: boolean, type: string, wealthLinkIncomeStatement: { __typename?: 'WealthLinkIncomeStatementType', enabled?: boolean }, wealthLinkModelSync: { __typename?: 'WealthLinkModelSyncType', enabled?: boolean } } }> } };

export type GetTenantLegalEntitiesQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetTenantLegalEntitiesQuery = { __typename?: 'Query', getTenantLegalEntities: { __typename?: 'GetTenantLegalEntitiesType', items: Array<{ __typename?: 'TenantLegalEntitiesType', id: string, legalEntities: Array<{ __typename?: 'LegalEntitiesType', customerName?: string, customerId: string, fcaRefNo: string, groupId: string }> }> } };

export type GetExternalEntityMapAllQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetExternalEntityMapAllQuery = { __typename?: 'Query', getExternalEntityMap: { __typename?: 'ExternalEntityMapResult', items: Array<{ __typename?: 'ExternalEntityMap', id?: string, externalId: string, internalId: string, fcaRefNo?: string, providerAppId?: string, bulkValAppId?: string, metadata: { __typename?: 'Metadata', displayName?: string } }> } };

export type GetIntegrationAndTenantLegalEntitiesQueryVariables = Types.Exact<{
  tenantLegalEntitiesId: Types.Scalars['String']['input'];
}>;


export type GetIntegrationAndTenantLegalEntitiesQuery = { __typename?: 'Query', getIntegrationAndTenantLegalEntities?: { __typename?: 'GetIntellifloIntegrationLegalResponse', errorMessage?: string, errorType?: string, success: boolean, statusCode: number, data?: Array<{ __typename?: 'IntellifloIntegrationLegalType', legalEntity: { __typename?: 'LegalEntitiesType', customerName?: string, customerId: string, fcaRefNo: string, groupId: string }, integrationSettings: { __typename?: 'IntellifloIntegrationType', id: string, tenantId: string, settings: { __typename?: 'IntegrationSettingsType', allowClientImport?: boolean, allowClientRelationshipImport?: boolean, allowDocumentUpload?: boolean, allowWealthLinkBulkValuation?: boolean, type: string, wealthLinkIncomeStatement: { __typename?: 'WealthLinkIncomeStatementType', enabled?: boolean }, wealthLinkModelSync: { __typename?: 'WealthLinkModelSyncType', enabled?: boolean } } } }> } };

export type CreateIntellifloTenantMutationVariables = Types.Exact<{
  input: Types.CreateIntellifloTenantInput;
}>;


export type CreateIntellifloTenantMutation = { __typename?: 'Mutation', createIntellifloTenant: { __typename?: 'IntellifloTenantResponse', data?: boolean, errorMessage?: string, errorType?: string, statusCode: number, success: boolean } };

export type UpdateIntellifloTenantMutationVariables = Types.Exact<{
  input: Types.CreateIntellifloTenantInput;
}>;


export type UpdateIntellifloTenantMutation = { __typename?: 'Mutation', updateIntellifloTenant: { __typename?: 'IntellifloTenantResponse', data?: boolean, errorMessage?: string, errorType?: string, statusCode: number, success: boolean } };


export const GetAllIntellifloTenantDocument = `
    query GetAllIntellifloTenant($limit: Int, $filter: IntellifloTenantFilterInput, $LastEvaluatedKey: LastEvaluatedInput) {
  getAllIntellifloTenant(
    limit: $limit
    filter: $filter
    LastEvaluatedKey: $LastEvaluatedKey
  ) {
    data {
      scannedCount
      LastEvaluatedKey {
        type
        id
        customerId
      }
      intellifloTenants {
        externalId
        id
        internalId
        metadata {
          displayName
        }
      }
    }
  }
}
    `;
export const GetTenantIntegrationSettingsDocument = `
    query GetTenantIntegrationSettings($id: String!) {
  getTenantIntegrationsetting(id: $id) {
    items {
      id
      tenantId
      settings {
        allowClientImport
        allowClientRelationshipImport
        allowDocumentUpload
        allowWealthLinkBulkValuation
        type
        wealthLinkIncomeStatement {
          enabled
        }
        wealthLinkModelSync {
          enabled
        }
      }
    }
  }
}
    `;
export const GetTenantLegalEntitiesDocument = `
    query GetTenantLegalEntities($id: String!) {
  getTenantLegalEntities(id: $id) {
    items {
      id
      legalEntities {
        customerName
        customerId
        fcaRefNo
        groupId
      }
    }
  }
}
    `;
export const GetExternalEntityMapAllDocument = `
    query GetExternalEntityMapAll($id: String!) {
  getExternalEntityMap(id: $id) {
    items {
      id
      externalId
      internalId
      metadata {
        displayName
      }
      fcaRefNo
      providerAppId
      bulkValAppId
    }
  }
}
    `;
export const GetIntegrationAndTenantLegalEntitiesDocument = `
    query GetIntegrationAndTenantLegalEntities($tenantLegalEntitiesId: String!) {
  getIntegrationAndTenantLegalEntities(
    tenantLegalEntitiesId: $tenantLegalEntitiesId
  ) {
    errorMessage
    errorType
    success
    data {
      legalEntity {
        customerName
        customerId
        fcaRefNo
        groupId
      }
      integrationSettings {
        id
        tenantId
        settings {
          allowClientImport
          allowClientRelationshipImport
          allowDocumentUpload
          allowWealthLinkBulkValuation
          type
          wealthLinkIncomeStatement {
            enabled
          }
          wealthLinkModelSync {
            enabled
          }
        }
      }
    }
    statusCode
  }
}
    `;
export const CreateIntellifloTenantDocument = `
    mutation CreateIntellifloTenant($input: CreateIntellifloTenantInput!) {
  createIntellifloTenant(input: $input) {
    data
    errorMessage
    errorType
    statusCode
    success
  }
}
    `;
export const UpdateIntellifloTenantDocument = `
    mutation UpdateIntellifloTenant($input: CreateIntellifloTenantInput!) {
  updateIntellifloTenant(input: $input) {
    data
    errorMessage
    errorType
    statusCode
    success
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    GetAllIntellifloTenant: build.query<GetAllIntellifloTenantQuery, GetAllIntellifloTenantQueryVariables | void>({
      query: (variables) => ({ document: GetAllIntellifloTenantDocument, variables })
    }),
    GetTenantIntegrationSettings: build.query<GetTenantIntegrationSettingsQuery, GetTenantIntegrationSettingsQueryVariables>({
      query: (variables) => ({ document: GetTenantIntegrationSettingsDocument, variables })
    }),
    GetTenantLegalEntities: build.query<GetTenantLegalEntitiesQuery, GetTenantLegalEntitiesQueryVariables>({
      query: (variables) => ({ document: GetTenantLegalEntitiesDocument, variables })
    }),
    GetExternalEntityMapAll: build.query<GetExternalEntityMapAllQuery, GetExternalEntityMapAllQueryVariables>({
      query: (variables) => ({ document: GetExternalEntityMapAllDocument, variables })
    }),
    GetIntegrationAndTenantLegalEntities: build.query<GetIntegrationAndTenantLegalEntitiesQuery, GetIntegrationAndTenantLegalEntitiesQueryVariables>({
      query: (variables) => ({ document: GetIntegrationAndTenantLegalEntitiesDocument, variables })
    }),
    CreateIntellifloTenant: build.mutation<CreateIntellifloTenantMutation, CreateIntellifloTenantMutationVariables>({
      query: (variables) => ({ document: CreateIntellifloTenantDocument, variables })
    }),
    UpdateIntellifloTenant: build.mutation<UpdateIntellifloTenantMutation, UpdateIntellifloTenantMutationVariables>({
      query: (variables) => ({ document: UpdateIntellifloTenantDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetAllIntellifloTenantQuery, useLazyGetAllIntellifloTenantQuery, useGetTenantIntegrationSettingsQuery, useLazyGetTenantIntegrationSettingsQuery, useGetTenantLegalEntitiesQuery, useLazyGetTenantLegalEntitiesQuery, useGetExternalEntityMapAllQuery, useLazyGetExternalEntityMapAllQuery, useGetIntegrationAndTenantLegalEntitiesQuery, useLazyGetIntegrationAndTenantLegalEntitiesQuery, useCreateIntellifloTenantMutation, useUpdateIntellifloTenantMutation } = injectedRtkApi;

