export type User = { roles: Role[]; id: string };

export type Role = keyof typeof ROLES;
export type Permission = (typeof ROLES)[Role][number];

export const ROLES = {
  SuperAdmin: [
    "view:dashboard",

    "view:changelog",

    "view:events",

    "view:jobs",

    "view:machines",
    "create:machines",

    "view:ip-whitelists",
    "create:ip-whitelists",
    "update:ip-whitelists",

    "view:io-app-config",
    "create:io-app-config",
    "update:io-app-config",

    "view:io-integrations",
    "create:io-integrations",
    "update:io-integrations",

    "view:entity-mapping",
    "create:entity-mapping",
    "update:entity-mapping",

    "view:io-integrations",
    "create:io-integrations",
    "update:io-integrations",

    "view:portal-onboarding",
    "create:portal-onboarding",
    "update:portal-onboarding",

    "view:invester-onboarding",
    "create:invester-onboarding",
    "update:invester-onboarding",

    "view:advisor-onboarding",
    "create:advisor-onboarding",
    "update:advisor-onboarding",

    "view:admin-onboarding",
    "create:admin-onboarding",
    "update:admin-onboarding"
  ],
  AdminEdit: [
    "view:dashboard",

    "view:changelog",

    "view:events",

    "view:jobs",

    "view:machines",
    "update:machines",

    "view:ip-whitelists",
    "create:ip-whitelists",
    "update:ip-whitelists",

    "view:io-app-config",
    "create:io-app-config",
    "update:io-app-config",

    "view:io-integrations",
    "create:io-integrations",
    "update:io-integrations",

    "view:entity-mapping",
    "create:entity-mapping",
    "update:entity-mapping",

    "view:portal-onboarding",
    "create:portal-onboarding",
    "update:portal-onboarding",

    "view:invester-onboarding",
    "create:invester-onboarding",
    "update:invester-onboarding",

    "view:advisor-onboarding",
    "create:advisor-onboarding",
    "update:advisor-onboarding",

    "view:admin-onboarding",
    "create:admin-onboarding",
    "update:admin-onboarding"
  ],
  AdminView: [
    "view:dashboard",

    "view:changelog",

    "view:events",

    "view:jobs",

    "view:machines",

    "view:ip-whitelists",

    "view:io-app-config",

    "view:io-integrations",

    "view:entity-mapping",

    "view:io-integrations",

    "view:portal-onboarding",

    "view:invester-onboarding",

    "view:advisor-onboarding",

    "view:admin-onboarding"

  ],
} as const;

export function hasPermission(
  attributes: { [key: string]: any },
  permission: Permission
) {
  const role = attributes["custom:role"] as Role;
  return (ROLES[role] as readonly Permission[])?.includes(permission);
}
