import restUtils from "core/utils/rest";
import { Investor, InvestorPage } from "../model";


const UMAPI = "UserManagementApi";
const INVESTOR = "investor";

class InvestorStore {

  getInvestors = async (payload: any) => {
    return await restUtils.call<InvestorPage>(UMAPI, INVESTOR , "GET", payload);
  };

  getInvestor = async (id: any) => {
    return await restUtils.call<Investor>(UMAPI, INVESTOR + `/${id}`, "GET");
  };

  createInvestor = async (payload: any) => {
    return await restUtils.call<any>(UMAPI, INVESTOR, "POST", payload);
  };

  updateInvestor = async (id: any, payload: any) => {
    return await restUtils.call<any>(UMAPI, INVESTOR + `?id=${id}`, "PUT", payload);
  };
  disableMFAInvestor = async (payload: any) => {
    return await restUtils.call<any>(UMAPI, INVESTOR + "/modify-mfa", "PATCH", payload);
  };
}

const investorStore = new InvestorStore();
export default investorStore;
