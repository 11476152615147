import React from "react";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FieldProps } from "formik";

interface DatePickerFieldProps {
  field: FieldProps["field"];
  form: FieldProps["form"];
  label: string;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({
  field,
  form,
  label,
}) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        name={name}
        value={value instanceof Date ? value : null}
        onChange={(newValue) => {
          setFieldValue(name, newValue);
        }}
        maxDate={new Date()}
        format="MM/dd/yyyy"
        slots={{
          textField: (params) => (
            <TextField
              {...params}
              label={label}
              fullWidth
              error={!!form.errors[name] && form.touched[name]}
              helperText={form.errors[name] && form.touched[name] && form.errors[name]}
              size="small"
            />
          ),
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerField;
