import React, { forwardRef, useState } from "react";
import {
  TableCell,
  TableRow,
  Snackbar,
  Alert,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  DialogActions,
  colors,
} from "@mui/material";
import { useNavigate } from "react-router";
import MenuComponent, { MenuItem } from "components/MenuComponent";
import { Investor } from "../../model";
import investorStore from "../../api";
import { enqueueSnackbar } from "notistack";
import { hasPermission } from "core/utils/roles";

interface TableRowProps {
  row: Investor;
  attributes: { [key: string]: any }
}

const InvestorUsersRow = forwardRef<HTMLTableRowElement, TableRowProps>((props) => {
  const navigate = useNavigate();
  const row = props.row;
  const attributes = props.attributes;

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const { disableMFAInvestor } = investorStore;
  const handleDisableMFA = async () => {
    try {
      await disableMFAInvestor({ username: row.username, status: false });
      enqueueSnackbar(`MFA disabled successfully for ${row.username}`, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(`Error disabling MFA for ${row.username}`, { variant: "error" });

    }
  };
  const options: MenuItem[] = [
    {
      label: hasPermission(attributes, "update:invester-onboarding") ? "Edit" : "View",
      onClick: () => navigate("/investor-users/add-edit/" + row.id),
    },
    ...(hasPermission(attributes, "update:invester-onboarding") ? [{
      label: "Disable MFA",
      onClick: handleOpenDialog,
    }] : []),
    {
      label: "User Activity",
      onClick: () => navigate(`/investor-users/user-activity/${row.id}`, { state: { username: row.username } })
    }
  ];

  return (
    <React.Fragment>
      <TableRow sx={{ borderBottom: "none", "& > *": { borderBottom: "unset" } }}>
        <TableCell>{row?.first_name} {row?.last_name}</TableCell>
        <TableCell>{row?.username}</TableCell>
        <TableCell>{row?.email}</TableCell>
        <TableCell>{row?.phone_number}</TableCell>
        <TableCell>{row?.customer_id}</TableCell>
        <TableCell>{row?.investor_id}</TableCell>
        <TableCell>{row?.employee_id}</TableCell>
        <TableCell>
          <MenuComponent options={options} />
        </TableCell>
      </TableRow>

      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="confirm-disable-mfa"
      >
        <DialogTitle id="confirm-disable-mfa">
          <Typography variant="h4">Confirm Disable MFA</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to disable MFA for {row.username} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDisableMFA}
            sx={{
              backgroundColor: colors.red[600],
              color: "white",
              "&:hover": {
                backgroundColor: colors.red[800],
              },
            }}
          >
            Disable MFA
          </Button>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );
});

export default InvestorUsersRow;
