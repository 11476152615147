import React, { useState } from "react";
import * as yup from "yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion, AccordionDetails, AccordionSummary,
  Box, Button, MenuItem, Typography
} from "@mui/material";
import { Field, FieldArray, Form, Formik, FormikProps } from "formik";
import { TextInput } from "../../../../../components/inputs";
import { SetState } from "../../../../../app/types";
import DatePickerField from "components/inputs/DatePickerField";

type FilterInput = {
  customer: string;
  name: string;
  username: string;
  email: string;
  createdAt: string;
  investorId: string;
  employeeId: string;
}
const fields: { key: keyof FilterInput, label: string }[] = [
  { key: "customer", label: "Customer" },
  { key: "name", label: "Name" },
  { key: "email", label: "Email" },
  { key: "username", label: "Username" },
  { key: "employeeId", label: "Employee Id" },
  { key: "createdAt", label: "Created At" }
];

const OPERATIONS = [
  { key: "like", label: "Like" },
  { key: "equal", label: "Eq" }
];

const DATE_DIRECTION = ["Before", "After"];

const validationSchema = yup.object({
  filters: yup.array(yup.object({
    filterName: yup.string().required().label("Filter Name"),
    operation: yup.string().required().label("Operation"),
    value: yup
      .string()
      .required()
      .label("Value")
  }))
});


export type InputValues = {
  filterName: keyof FilterInput | "",
  operation: string,
  value: string | Date
};
type InitialValues = {
  filters: InputValues[]
}
const initialValues: InitialValues = {
  filters: [{ filterName: "", operation: "", value: "" }]
};

type Props = { setFilters: SetState<InputValues[] | null> };
const Filter: React.FC<Props> = ({ setFilters }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const onSubmit = (values: InitialValues) => {
    setFilters(values.filters);
  };

  return (
    <Box>
      <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
        <AccordionSummary sx={{ "&.Mui-expanded": { minHeight: "32px" } }} expandIcon={<ExpandMoreIcon />}>
          <Typography>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "16px" }}>
          <Box>
            <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
              {(props: FormikProps<InitialValues>) => {
                const { values } = props;
                return (
                  <Form>
                    <FieldArray
                      name="filters"
                      render={arrayHelpers => (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 80px", gap: "20px" }}>
                            {values.filters.map((_, index) => (
                              <>
                                <Field
                                  select
                                  size="small"
                                  label="Filter Name"
                                  component={TextInput}
                                  name={`filters[${index}].filterName`}
                                >
                                  {fields.map((el) => (
                                    <MenuItem key={el.key} value={el.key}>{el.label}</MenuItem>
                                  ))}
                                </Field>
                                {
                                  values.filters[index].filterName === "createdAt"
                                    ? (<Field
                                      select
                                      size="small"
                                      label="Operation"
                                      component={TextInput}
                                      name={`filters[${index}].operation`}
                                    >
                                      {DATE_DIRECTION.map((el) => (
                                        <MenuItem key={el} value={el}>{el}</MenuItem>
                                      ))}
                                    </Field>)
                                    : (<Field
                                      select
                                      size="small"
                                      label="Operation"
                                      component={TextInput}
                                      name={`filters[${index}].operation`}
                                    >
                                      {OPERATIONS.map((el) => (
                                        <MenuItem key={el.key} value={el.key}>{el.label}</MenuItem>
                                      ))}
                                    </Field>)
                                }
                                { values.filters[index].filterName === "createdAt"
                                  ? (
                                    <Field
                                      name={`filters[${index}].value`}
                                      label="Value (Date)"
                                      component={DatePickerField}
                                    />
                                  )
                                  :(<Field
                                    size="small"
                                    label="Value"
                                    component={TextInput}
                                    name={`filters[${index}].value`}
                                  />) }
                                <Button
                                  type="button"
                                  size="small"
                                  variant="outlined"
                                  sx={{ maxHeight: "38px" }}
                                  color="primary"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  Remove
                                </Button>
                              </>
                            ))}
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "20px" }}>
                            <Button
                              variant="outlined"
                              type="button"
                              onClick={() => arrayHelpers.push(initialValues.filters[0])}
                            >
                              Add a filter
                            </Button>
                            <Button variant="contained" type="submit">Submit</Button>
                          </Box>
                        </Box>
                      )}
                    />
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Filter;
