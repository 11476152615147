import "@mui/material";
import { createContext, useState, useMemo } from "react";
import { ThemeOptions, createTheme, styled } from "@mui/material/styles";
import { Color, PaletteMode } from "@mui/material";
import { Theme } from "@emotion/react";
import { PaletteColorOptions, PaletteOptions } from "@mui/material/styles/createPalette";
import { mergeWith } from "lodash";

export const tokens = (mode: PaletteMode | undefined): typeof palettes["light"] => {
  return palettes[mode || "light"];
};

declare module "@mui/material/styles" {
  interface Palette extends CustomColors {
  }

  interface PaletteOptions extends CustomColors {
  }
}
type CustomColors = {
  backgroundColor: string,
  cardBackground: string,
  cardBorderColor: string,
  greenAccent: Color,
  redAccent: Color,
  blueAccent: Color,
  primaryAccent: Color,
};

const basePalette = {
  grey: {
    50: "#000",
    100: "#141414",
    200: "#292929",
    300: "#3d3d3d",
    400: "#525252",
    500: "#666666",
    600: "#858585",
    700: "#a3a3a3",
    800: "#c2c2c2",
    900: "#fff",
  },
  primaryAccent: {
    100: "#d0d1d5",
    200: "#f5f8fc",
    300: "#727681",
    400: "#0660D4",
    500: "#0549A1",
    600: "#101624",
    700: "#0c101b",
    800: "#080b12",
    900: "#040509",
  },
  greenAccent: {
    100: "#e1faeb",
    200: "#c3f5d7",
    300: "#a6f1c4",
    400: "#88ecb0",
    500: "#6ae79c",
    600: "#55b97d",
    700: "#408b5e",
    800: "#2a5c3e",
    900: "#152e1f",
  },
  redAccent: {
    100: "#f8dcdb",
    200: "#f1b9b7",
    300: "#e99592",
    400: "#e2726e",
    500: "#db4f4a",
    600: "#af3f3b",
    700: "#832f2c",
    800: "#58201e",
    900: "#2c100f",
  },
  blueAccent: {
    100: "#f5f8fc",
    200: "#add6ff",
    300: "#85c2ff",
    400: "#5275a1",
    500: "#3399ff",
    600: "#297acc",
    700: "#1f5c99",
    800: "#143d66",
    900: "#0a1f33",
  },
};
const appPalette = {
  secondary: {
    light: basePalette.blueAccent[400],
    main: basePalette.blueAccent[500],
    dark: basePalette.blueAccent[600],
  },
  neutral: {
    dark: basePalette.grey[700],
    main: basePalette.grey[500],
    light: basePalette.grey[100],
  },
};


const palettes: { dark: PaletteOptions & CustomColors, light: PaletteOptions & CustomColors } = {
  light: mergeWith({}, basePalette, appPalette, {
    backgroundColor: "#f9f9f9",
    cardBackground: "#ffffff",
    cardBorderColor: "#e6ebf1",
    primary: {
      light: basePalette.primaryAccent[200],
      main: basePalette.primaryAccent[400],
      dark: basePalette.primaryAccent[500],
      shadowDark: "#406eb829",
      shadowLight: "#406eb80a"
    }
  }),

  dark: mergeWith({}, basePalette, appPalette, {
    backgroundColor: "#1d2125",
    cardBackground: "#1d2125",
    cardBorderColor: "#112c47",
    primary: {
      main: basePalette.grey[900],
      light: basePalette.primaryAccent[800],
      shadowDark: "#141414",
      shadowLight: "#141414"
    },
    grey: {
      50: "#fff",
      100: "#e0e0e0",
      200: "#c2c2c2",
      300: "#a3a3a3",
      400: "#858585",
      500: "#666666",
      600: "#525252",
      700: "#3d3d3d",
      800: "#292929",
      900: "#141414",
    },
    blueAccent: {
      100: "#141414",
      200: "#143d66",
      300: "#1f5c99",
      400: "#297acc",
      500: "#3399ff",
      600: "#5cadff",
      700: "#85c2ff",
      800: "#add6ff",
      900: "#f5f8fc",
    },
  }),
};

export const themeSettings = (mode: PaletteMode | undefined): ThemeOptions => {
  const colors = tokens(mode);
  return {
    palette: {
      mode,
      ...colors
    },
    typography: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: 14,

      h1: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 32,
      },
      h2: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 26,
        lineHeight: 1
      },
      h3: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 14,
      },
      h6: {
        fontFamily: ["Lato", "sans-serif"].join(","),
        fontSize: 10,
      },
    },
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: "Lato, sans-serif"
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            border: `1px ${getPrimaryMain(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100] : "#cccccc")}`,
            ":hover": {
              border: `1px ${getPrimaryMain(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100] : "#cccccc")}`,
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Montserrat, sans-serif",
            fontWeight: 600,
            color: colors?.grey?.[900],
            paddingLeft:20,
            paddingRight: 20,
            paddingTop: 5,
            paddingBottom:5,
            textTransform: "none",
            fontSize: 15,
            borderRadius: 90,
            ":hover": {
              boxShadow: `0px 0px 10px ${getPrimaryDark(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100] : "#cccccc")}`,
            },
          },
          outlined: {
            color: getPrimaryMain(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100]: "#111"),
            ":hover": {
              color: "#ffffff",
              backgroundColor: getPrimaryDark(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100] : "#cccccc"),
              boxShadow: `0px 0px 10px ${getPrimaryDark(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100] : "#cccccc")}`
            }
          },
        },
        defaultProps: {
          variant: "contained"
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: `${colors.blueAccent[100]} !important`
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: "Lato, sans-serif"
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            border: `1px ${getPrimaryMain(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100] : "#cccccc")}`
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            fontSize: 14,
            "&:hover fieldset": {
              borderColor: getPrimaryMain(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100] : "#cccccc"),
              borderWidth: "1px",
            }
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: `0px 4px 16px ${getPrimaryShadowLight(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100] : "#cccccc")}, 0px 2px 4px ${getPrimaryShadowDark(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100] : "#cccccc")}`
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: `0px 0px 1px ${getPrimaryShadowLight(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100] : "#cccccc")}, 0px 2px 4px ${getPrimaryShadowDark(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100] : "#cccccc")}`,
            borderRadius: 12,
            border: "0px"
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            fontSize: 15,
            ":hover" : {
              color: getPrimaryMain(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100] : "#cccccc"),
              backgroundColor: getPrimaryLight(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100] : "#cccccc"),
              boxShadow: `0px 3px 5px ${getPrimaryShadowLight(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100] : "#cccccc")}, 0px 5px 4px ${getPrimaryShadowDark(colors.primary, colors?.grey?.[100] ? colors?.grey?.[100] : "#cccccc")}`
            }
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 14,
            fontFamily: "Montserrat, sans-serif",
            fontWeight: 500,
            color: colors?.grey?.[400],
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: 18
          }
        }
      }
    },
  };
};

export const ColorModeContext: any = createContext({
  toggleColorMode: () => ({}),
});

const getPrimaryMain = (primary: PaletteColorOptions | undefined, fallback: string) => {
  return primary && "main" in primary ? primary.main : fallback;
};

const getPrimaryDark = (primary: PaletteColorOptions | undefined, fallback: string) => {
  return primary && "dark" in primary ? primary.dark : fallback;
};

const getPrimaryLight = (primary: PaletteColorOptions | undefined, fallback: string) => {
  return primary && "light" in primary ? primary.light : fallback;
};

const getPrimaryShadowDark = (primary: PaletteColorOptions | undefined, fallback: string) => {
  return primary && "shadowDark" in primary ? primary.shadowDark : fallback;
};

const getPrimaryShadowLight = (primary: PaletteColorOptions | undefined, fallback: string) => {
  return primary && "shadowLight" in primary ? primary.shadowLight : fallback;
};

export const useMode = () => {
  const [mode, setMode] = useState<PaletteMode | undefined>("light");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prev) => (prev === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme: Theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
